<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Envío de documentos" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true" :tooltip="`Resumen de la distribución por email de documentos aceptados en ${$t('generals.SII')}.`" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="dialogExport = true" outlined :disabled="count === 0"><v-icon left size="20">mdi-export-variant</v-icon>Exportar</v-btn>
        <v-query-builder :query-map="filters" model="documents" />
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-data-table
          :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}"
          :headers="currentHeader"
          :items="list"
          :items-per-page="pagination"
          hide-default-header
          disable-sort
          mobile-breakpoint
          hide-default-footer
          no-data-text="No existen datos"
          @click:row="(list) => { hasLink ? $router.push({name: 'DocumentsRetrieve', params: {id: list.document.id, pagination: $route.query.page ?? 1}}).catch(() => {}) : ''}"
          fixed-header
          :height="heightTable"
        >
          <template v-slot:header="{ props: { headers } }">
            <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
            <v-divider style="position: absolute; z-index: 2; margin-top: -6px; margin-left: -5px; min-width: 100%" />
          </template>
          <template v-slot:[`item.document__document_type`]="{item}">
            <span class="body-2 d-inline-block text-truncate" style="max-width: 95%;">{{((item || {}).document || {}).document_type | nameDocumentsType}}</span>
            <span class="caption fontDraw--text d-block">{{$store.state?.auth?.account?.country === 'PE' ? `${item.document.prefix} - ${item.document.number}` : item.document.number }}</span>
          </template>

          <template v-slot:[`item.document__taxpayer__name`]="{item}">
            <template v-if="!!Object.keys(item.document.taxpayer || {}).length">
              <span class="body-2 d-inline-block text-truncate" style="max-width: 100%;">{{((item || {}).document.taxpayer || {}).name}}</span>
              <span class="caption mt-n2 fontDraw--text d-block" v-if="((item || {}).document.taxpayer || {}).tax_id">{{$t('generals.RUT')}} {{item.document.taxpayer.tax_id}}</span>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <template v-else-if="!!Object.keys(item.document.customer || {}).length">
              <span class="body-2 d-inline-block text-truncate" style="max-width: 100%;">{{((item || {}).document.customer || {}).name}}</span>
              <span class="caption mt-n2 fontDraw--text d-block" v-if="((((item || {}).document || {}).customer || {}).taxpayer || {}).tax_id">{{$t('generals.RUT')}} {{item.document.customer.taxpayer.tax_id}}</span>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <span class="body-2 text--disabled font-italic" v-if="!Object.keys(item.document.customer || {}).length && !Object.keys(item.document.taxpayer || {}).length">Sin especificar</span>
          </template>

          <template v-slot:[`item.created`]="{item}">
            <span class="body-1">{{item.created | dateTime}}</span>
          </template>

          <template v-slot:[`item.recipient`]="{item}">
            <v-tooltip color="bgSearch" bottom width="300px" transition="scale-transition">
              <template v-slot:activator="{ on }">
                <span class="fontBody--text body-1" v-on="on">
                  <v-icon size="20" color="fontBody">mdi-account-multiple</v-icon>
                  ({{item.recipient.length}})
                </span>
              </template>
              <span class="d-block pt-2 pb-1">
                <span class="px-3 d-block" v-for="recipient in item.recipient" :key="recipient">
                  <span class="d-inline-block text-truncate" style="max-width: 200px;">{{recipient}}</span>
                </span>
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-chip v-if="item" color="lightBlue" small label>Enviado<v-icon right>mdi-check</v-icon></v-chip>
          </template>

          <template v-slot:[`item.auto`]="{item}">
            <span class="body-2">{{ item.auto ? 'Automático' : 'Manual' }}</span>
            <span class="mt-n2 caption fontDraw--text d-block"  v-if="item.user">{{item.user}}</span>
          </template>
        </v-data-table>
        <VMainPagination :count="count" />
      </template>
    </v-col>

    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-medium">Descarga de documentos emitidos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <!-- <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="fontBody--text body-1 font-weight-bold">Resumen de la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="fontBody--text body-1 font-weight-bold">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="pt-2">
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-checkbox class="ma-0 vcheckbox" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" dense hide-details />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- alert -->
          <v-alert class="inf-alert-blue primary--text rounded-md mt-2" :height="58" dense text>
            <div class="d-flex pa-0 my-1">
              <v-icon color="primary" size="22">mdi-information-outline</v-icon>
              <div class="ml-4">
                <span class="body-2" style="line-height: 14px;">Esta acción
                  <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. Recibirás un
                  <span class="font-weight-bold"> correo electrónico con el enlace de descarga.</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="primary" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import ListViewMixin from '@/mixins/ListViewMixin'
import MiniHeader from '@/components/commons/MiniHeader'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import VMainPagination from '@/components/commons/VMainPagination'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'

export default {
  components: {
    MiniHeader,
    VMainPagination,
    SkeletonList,
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    scroll: 0,
    types: [],
    fileFormat: ['XLSX', 'CSV'],
    dialogExport: false,
    loading: false,
    documentTypes: DocumentTypes,
    headers: [
      { text: 'Documento', value: 'document__document_type', sortable: true },
      { text: 'Fecha de envío', value: 'created', align: 'end', sortable: true },
      { text: 'Estado', value: 'status', width: 154 }, // cellClass: 'px-5',
      { text: 'Tipo de envío', value: 'auto', sortable: true},
      { text: 'Destinatarios', value: 'recipient', align: 'center' },
      { text: 'Receptor', value: 'document__taxpayer__name', sortable: true }
    ],
    filters: [
      {
        type: 'text',
        id: 'document__customer__taxpayer__name',
        label: 'Cliente',
        value: null,
        category: 'a',
        operators: ['contiene'],
        countries: ['PE', 'CL']
      },
      {
        type: 'text',
        id: 'document__customer__taxpayer__tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      },
      {
        type: 'date',
        id: 'document__date',
        label: 'Fecha de envío',
        value: null,
        value2: null,
        category: 'b',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
        countries: ['PE', 'CL']
      },
      {
        type: 'select',
        id: 'document__document_type',
        value: null,
        label: 'Tipo de documento',
        category: 'c',
        choices: [],
        countries: ['PE', 'CL']
      },
      {
        type: 'text',
        id: 'document__prefix',
        label: 'Serie',
        value: null,
        category: 'c',
        countries: ['PE']
      },
      {
        type: 'numeric',
        id: 'document__number',
        label: 'Número de folio',
        value: null,
        value2: null,
        category: 'c',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
        countries: ['PE', 'CL']
      },
      {
        type: 'select',
        id: 'auto',
        label: 'Tipo de envío',
        value: null,
        choices: [
          {
            value: 'True',
            label: 'Automático'
          },
          {
            value: 'False',
            label: 'Manual'
          }
        ],
        category: 'd',
        countries: ['PE', 'CL']
      },
      {
        type: 'text',
        id: 'recipient',
        label: 'Mail',
        value: null,
        category: 'd',
        operators: [],
        countries: ['PE', 'CL']
      }
    ],
    breadcrumbs: {
      main: 'Reporte',
      children: [
        {
          text: 'Envío de documentos'
        }
      ]
    },
    list: [],
    selected: [],
    toggleDropdown: null,
    resetCheckbox: 0,
    isSelected: null
  }),
  computed: {
    heightTable () {
      if (this.$vuetify.breakpoint.height <= 1200 && this.list.length >= 16 && this.list.length <= 20) return 'calc(100vh - 210px)'
      if (this.list.length > 20) return 'calc(100vh - 165px)'
      if (this.list.length < 16) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      count: state => state.messages.messagesCount,
      messagesList: state => state.messages.messagesList
    }),
    currentHeader () {
      this.headers.forEach((item, index) => {
        if (this.$vuetify.breakpoint.width < 1210 && item.value === 'document_type') {
          item.text = 'T. documento'
        }
        if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'number') {
          item.width = 185
          item.text = this.$t('generals.Folio #')
        }
      })
      return this.headers
    }
  },
  watch: {
    selected (val) {
      if (val.length !== this.list.length) this.resetCheckbox += 1
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.list
        this.isSelected = true
      }
    }
  },
  created () {
    this.getList()
    this.filters = this.filters?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
    this.setFilterDocumentType()
  },
  methods: {
    getFileFormat (val = null) {
      if (!this.types?.find((s) => s === val)) this.types.push(val)
      else {
        this.types.splice(this.types?.indexOf(val), 1)
        this.types = [...this.types]
      }
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    setFilterDocumentType () {
      const currentDocumentType = this.documentTypes.filter(({country}) => country === this.$store.getters['base/currentCountry'])
      if (currentDocumentType.length) {
        const index = this.filters.findIndex(({id}) => id === 'document__document_type')
        this.filters[index].choices = currentDocumentType.map(({name, id}) => {
          return {
            label: name,
            value: id
          }
        })
      }
    },
    getList () {
      this.loading = true
      this.$store.dispatch('messages/GET', {
        resource: 'messages',
        query: {...this.$route.query, ...{expand: 'document'}}
      })
      .then(() => {
        this.messagesList.forEach((item, i) => {
          item.index = i
        })
        this.list = [...this.messagesList]
      })
      .finally(() => {
        this.loading = false
      })
    },
    createExport () {
      this.dialogExport = false
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'messages/export',
        query: {
          ...this.$route.query,
          expand: 'document',
          extension: this.types.toString().toLowerCase()
          // ...(((['perPage'].includes(this.toggleDropdown) || (this.selected.length > 0 && this.selected.length <= 20)) && !['all', 'filtered'].includes(this.toggleDropdown)) && { id__in: this.selected.map(({id}) => id).toString()})
        },
        loader: true
      })
      .then(() => {
        this.$dialog.message.info('Se ha iniciado el proceso de descarga y se le notificará via mail, una vez que haya finalizado', { timeout: 10000 })
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
      })
      .finally(() => {
        this.loading = false
        this.types = []
      })
    },
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.list
    }
  }
}
</script>